import CableIcon from "@mui/icons-material/Cable";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import ListAltIcon from "@mui/icons-material/ListAlt";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { Box, Card, CardContent, Typography } from "@mui/material";
import styled from "@mui/styled-engine";
import SimpleLink from "components/molecules/SimpleLink";
import React from "react";

const Cards: React.FC = (props) => {
  const ConnectCard = styled(Card)({
    height: "8rem",
  });
  const CardTitle = styled(Box)({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    columnGap: 5,
    color: "#3777A4",
    "& .MuiTypography-root": {
      fontWeight: "bold",
    },
  });

  return (
    <Box>
      <Box sx={{ pt: 3, pl: 3, pr: 3 }}>
        <Typography>
          コラボフローコネクトはコラボフローのインスタンス同士を接続し、組織を越えたワークフローを実現するサービスです
        </Typography>
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          columnGap: 4,
          rowGap: 3,
          p: 6,
          textAlign: "left",
        }}
      >
        <ConnectCard>
          <SimpleLink to="connection">
            <CardContent>
              <CardTitle>
                <CableIcon fontSize="large" />
                <Typography>接続情報の管理</Typography>
              </CardTitle>
              <div>
                自分管理のコラボフロー接続情報の登録と、組織外との接続情報を管理します
              </div>
            </CardContent>
          </SimpleLink>
        </ConnectCard>
        <ConnectCard>
          <SimpleLink to="connection/external">
            <CardContent>
              <CardTitle>
                <LockOpenIcon fontSize="large" />
                <Typography>接続許可リクエスト</Typography>
              </CardTitle>
              <div>組織外から届いた接続許可リクエストを管理します</div>
            </CardContent>
          </SimpleLink>
        </ConnectCard>
        <ConnectCard>
          <SimpleLink to="log">
            <CardContent>
              <CardTitle>
                <ListAltIcon fontSize="large" />
                <Typography>ログ</Typography>
              </CardTitle>
              <div>
                コラボフローコネクトの利用時に記録されたログを確認できます
              </div>
            </CardContent>
          </SimpleLink>
        </ConnectCard>
        <ConnectCard>
          <SimpleLink to="apikey">
            <CardContent>
              <CardTitle>
                <VpnKeyIcon fontSize="large" />
                <Typography>APIキー</Typography>
              </CardTitle>
              <div>
                コラボフローと連携するためのAPIキーを確認できます
              </div>
            </CardContent>
          </SimpleLink>
        </ConnectCard>
      </Box>
    </Box>
  );
};
export default Cards;
