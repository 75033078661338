import { useAuth0 } from "@auth0/auth0-react";
import {
  BugReport as BugReportIcon,
  CheckCircle as CheckCircleIcon,
  Error as ErrorIcon,
  Info as InfoIcon,
  Warning as WarningIcon,
} from "@mui/icons-material";
import { Box, Button, Pagination } from "@mui/material";
import {
  DataGrid,
  DataGridProps,
  GridColDef,
  gridPageCountSelector,
  gridPageSelector,
  GridRowsProp,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import axios from "axios";
import { format as formatTz, utcToZonedTime } from "date-fns-tz";
import React, { useEffect, useState } from "react";
import { DataGridDialog } from "../../../components/molecules/DataGridDialog";

interface LogDataGirdProps extends DataGridProps {
  rows: GridRowsProp;
  setGridDialogContent: Function;
  setGridDialogOpen: Function;
  isGridDialogOpen: boolean;
  gridDialogContent: any;
  collaboflowInstanceName: string;
  setRows: Function;
}

interface FeatchMoreLogsProps {
  fetchLogs: Function;
  collaboflowInstanceName: string;
  nextPagenationKey: any;
  rowCount: number;
}

/**
 * ログDataGrid カラムの定義
 */
const logColumns: GridColDef[] = [
  {
    field: "id",
    headerName: "ID",
    width: 20,
    hide: true,
  },
  {
    field: "loglevel",
    headerName: "種別",
    width: 100,
    renderCell: (params: any) => (
      <span>
        {params.value === "success" && <CheckCircleIcon color="success" />}
        {params.value === "warning" && <WarningIcon color="warning" />}
        {params.value === "error" && <ErrorIcon color="error" />}
        {params.value === "info" && <InfoIcon color="info" />}
        {params.value === "debug" && <BugReportIcon color="warning" />}
      </span>
    ),
  },
  {
    field: "createdate",
    headerName: "日時",
    type: "dateTime",
    width: 200,
    renderCell: (params) => <span>{params.value}</span>,
  },
  {
    field: "info",
    headerName: "情報",
    width: 460,
  },
];

/**
 * 次のログレコード取得コンポーネント
 */
export const FeatchMoreLogs: React.FC<FeatchMoreLogsProps> = (props) => {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Box
      sx={{
        p: 1,
        pr: 3,
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
      }}
    >
      {props.nextPagenationKey !== undefined && (
        <Button
          onClick={() => {
            props.fetchLogs(props.collaboflowInstanceName);
          }}
        >
          更にレコードを取得
        </Button>
      )}
      <Pagination
        count={pageCount}
        page={page + 1}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
      <Box>{props.rowCount}件</Box>
    </Box>
  );
};

/**
 * ログデータグリッドコンポーネント
 * @param props
 * @returns
 */
export const LogDataGird: React.FC<LogDataGirdProps> = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const [nextPagenationKey, setNextPagenationKey] = useState();

  // ログ情報の取得処理
  const fetchLogs = async (collaboflowInstanceName: string) => {
    const accessToken = await getAccessTokenSilently();
    const lastEvaluatedKeyToken =
      nextPagenationKey !== undefined
        ? encodeURIComponent(JSON.stringify(nextPagenationKey))
        : "";

    axios({
      method: "get",
      baseURL: process.env.REACT_APP_API_SERVER_URL,
      url: "/admin/log",
      params: {
        instance_name: collaboflowInstanceName,
        last_evaluated_key: lastEvaluatedKeyToken,
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }).then((res) => {
      let items = res.data.response.Items;
      setNextPagenationKey(res.data.response.LastEvaluatedKey);
      let records = items.map((item: any, index: number) => {
        let zonedCreateAt = utcToZonedTime(item.create_at, "Asia/Tokyo");
        return {
          id: item.sk,
          createdate: formatTz(zonedCreateAt, "yyyy/MM/dd HH:mm"),
          loglevel: item.log_level,
          info: item.info.message,
        };
      });
      const addedRows = props.rows.concat(records);
      props.setRows(addedRows);
    });
  };
  let collaboflowInstanceName = props.collaboflowInstanceName;

  // 初回読み込み時の処理
  useEffect(() => {
    if (collaboflowInstanceName.length === 0) {
      props.setRows([]);
      return;
    }
    if (props.rows.length > 0) {
      return;
    }
    (async () => {
      fetchLogs(collaboflowInstanceName);
    })();
  }, [collaboflowInstanceName]);

  const closeDataGridDialog = () => {
    props.setGridDialogOpen(false);
  };
  let rowCount = props.rows.length;

  return (
    <Box sx={{ height: 400, width: "100%", pb: 5 }}>
      <DataGrid
        rows={props.rows}
        columns={logColumns}
        pageSize={100}
        onRowClick={(params, event) => {
          props.setGridDialogContent(params.row.info);
          props.setGridDialogOpen(true);
        }}
        components={{
          Footer: FeatchMoreLogs,
        }}
        componentsProps={{
          footer: {
            fetchLogs,
            collaboflowInstanceName,
            nextPagenationKey,
            rowCount,
          },
        }}
      ></DataGrid>
      <DataGridDialog
        open={props.isGridDialogOpen}
        selectedValue={props.gridDialogContent}
        title="ログの詳細"
        onClose={closeDataGridDialog}
      ></DataGridDialog>
    </Box>
  );
};

export default LogDataGird;
