import { useAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { Loader } from "../../../components/molecules/Loader";

interface DisconnectDialogProps extends DialogProps {
  setSnackbarState: Function;
  setDisconnectDialogOpen: Function;
  setCollaboflowInstanceName: Function;
}

/**
 * Teamsの接続解除ダイアログ
 * @param props
 * @returns
 */
export const DisconnectDialog: React.FC<DisconnectDialogProps> = (props) => {
  const { reset } = useFormContext();
  const { getAccessTokenSilently } = useAuth0();
  const [progress, setProgress] = useState(false);
  /**
   * Teamsとの連携を解除する
   */
  const disconnectTeams = async () => {
    closeDisconnectDialog();
    setProgress(true);
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_TEAMS_AUTH0_AUDIENCE,
    });
    try {
      const response = await axios({
        method: "delete",
        baseURL: process.env.REACT_APP_API_SERVER_URL,
        url: "/admin/settings",
        data: {},
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        validateStatus: (status) => {
          return status < 500;
        },
      });
      if (response.status === 200) {
        props.setSnackbarState(true, "success", "連携を解除しました。");
        reset();
        props.setCollaboflowInstanceName("");
      } else {
        props.setSnackbarState(
          true,
          "warning",
          "連携を解除できませんでした。\n " + response.data.messages
        );
      }
    } catch (error) {
      props.setSnackbarState(
        true,
        "error",
        "処理中にエラーが発生しました。連携を解除できませんでした。"
      );
    }
    setProgress(false);
    props.setDisconnectDialogOpen(false);
  };

  const closeDisconnectDialog = () => {
    props.setDisconnectDialogOpen(false);
  };

  return (
    <div>
      <Dialog open={props.open} onClose={closeDisconnectDialog}>
        <DialogTitle>連携の解除</DialogTitle>
        <DialogContent>
          <p>Teamsとの連携を解除します。</p>
          <p>
            連携を解除するとコラボフローインスタンス名等、コラボフロー for
            Teamsの設定で登録した全ての情報が削除され、Teamsとの連携機能は再度設定を行うまで利用できなくなります。
          </p>
          <p>
            ※Teams側にインストールしたアプリはTeams管理から手動で削除する必要があります。
          </p>
          <p>よろしければ「解除を実行」をクリックしてください。</p>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" onClick={disconnectTeams}>
            解除を実行
          </Button>
          <Button
            color="warning"
            variant="contained"
            onClick={closeDisconnectDialog}
          >
            キャンセル
          </Button>
        </DialogActions>
      </Dialog>
      <Loader open={progress} />
    </div>
  );
};
