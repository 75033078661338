import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
  SxProps,
} from "@mui/material";
import React, { ReactNode } from "react";
import {
  Path,
  RegisterOptions,
  useController,
  useFormContext,
} from "react-hook-form";
import HiddenField from "./HiddenField";

export type CrSelectChangeEvent = SelectChangeEvent;

/**
 * ラベルからKeyを取得
 * @param label Selectコンポーネントで選択されたラベル
 * @param options Selectコンポーネントで使用されるデータ配列
 * @returns
 */
export const getKeyByLabel = (label: string, options: any) => {
  if (label === undefined) {
    return "";
  }
  const data = options.find((option: any) => option.title === label);
  if (data === undefined) {
    return "";
  }
  return data.hasOwnProperty("key") ? data.key : "";
};

interface CustomSelectProps extends SelectProps {
  name: Path<any>;
  valueFieldName?: string;
  options: Array<any>;
  variant?: any;
  hideLabel?: boolean;
  helpertext?: React.ReactNode;
  formControlSx?: SxProps;
  rules?: Omit<RegisterOptions, "setValueAs">;
  callBack?: Function;
}

export const CnSelectField: React.FC<CustomSelectProps> = (props) => {
  const {
    name,
    options,
    variant,
    formControlSx,
    value,
    rules,
    valueFieldName,
    callBack,
    ...selectFieldProps
  } = props;
  const inputLabelId = name + "_label";
  const { control, setValue } = useFormContext();
  const hiddenName = valueFieldName || name + "_value";
  const {
    field: { ref, ...rest },
  } = useController({ name, rules, control });
  const formControlSxProps = formControlSx || { mb: 3 };

  const handleChange = (
    event: SelectChangeEvent<{ value: "" }>,
    child: ReactNode
  ) => {
    const label = event.target.value as string;
    const key = getKeyByLabel(label, options);
    setValue(hiddenName, key);
    setValue(name, label);
    if (callBack !== undefined) {
      callBack(event);
    }
  };

  return (
    <FormControl sx={formControlSxProps}>
      {!props.hideLabel && (
        <InputLabel shrink id={inputLabelId}>
          {props.label}
        </InputLabel>
      )}
      <Select
        id={name}
        labelId={inputLabelId}
        inputRef={ref}
        variant={variant || "outlined"}
        displayEmpty
        {...rest}
        {...selectFieldProps}
        onChange={handleChange}
      >
        <MenuItem value="" key="Null">
          未選択
        </MenuItem>
        {options.map((option) => (
          <MenuItem value={option.title} key={option.key}>
            {option.title}
          </MenuItem>
        ))}
      </Select>
      {props.helpertext && <FormHelperText>{props.helpertext}</FormHelperText>}
      <HiddenField id={hiddenName}></HiddenField>
    </FormControl>
  );
};

export default CnSelectField;
