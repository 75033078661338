import { Auth0Provider } from "@auth0/auth0-react";

export const Auth0ProviderWithConfig = ({ children }: any) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const audience = process.env.REACT_APP_TEAMS_AUTH0_AUDIENCE;

  if (!(domain && clientId && audience)) {
    return null;
  }
  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      audience={process.env.REACT_APP_TEAMS_AUTH0_AUDIENCE}
    >
      {children}
    </Auth0Provider>
  );
};
