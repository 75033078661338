import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
} from "@mui/material";
import React from "react";

interface ConfirmDialogProps extends DialogProps {
  setDialogOpen: Function;
  dialogMessage: string;
  callBack: Function;
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = (props) => {
  const closeDialog = () => {
    props.setDialogOpen(false);
  };
  const proceed = () => {
    props.callBack();
    props.setDialogOpen(false);
  };
  return (
    <div>
      <Dialog open={props.open} onClose={closeDialog}>
        <Box sx={{ textAlign: "center", p: "2rem" }}>
          <DialogContent>{props.dialogMessage}</DialogContent>
          <DialogActions>
            <Button
              color="warning"
              variant="contained"
              onClick={closeDialog}
              startIcon={<CloseIcon />}
              sx={{ ml: 2 }}
            >
              キャンセル
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={proceed}
              startIcon={<CheckIcon />}
            >
              実行する
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
};
export default ConfirmDialog;
