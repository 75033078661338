import { useAuth0 } from "@auth0/auth0-react";
import { Box, Button } from "@mui/material";
import axios from "axios";
import React from "react";
import { useFormContext } from "react-hook-form";
import CnTextField from "../../../components/molecules/TextFiled";

interface LicenseRegistrationPanelProps {
  setSnackbarState: Function;
  settingTablekey: string;
  licenseActivated: boolean;
  setLicenseActivated: Function;
}

export const LicenseRegistrationPanel: React.FC<
  LicenseRegistrationPanelProps
> = (props) => {
  let configured = props.settingTablekey.length > 0 ? true : false;
  const {
    handleSubmit,
    formState: { errors },
  } = useFormContext();
  const { getAccessTokenSilently } = useAuth0();

  /**
   * ライセンスキーの登録
   * @param {*} data
   */
  const submitLicenseKey = async (data: any) => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_TEAMS_AUTH0_AUDIENCE,
    });
    try {
      const response = await axios({
        method: "post",
        baseURL: process.env.REACT_APP_API_SERVER_URL,
        url: "/admin/settings/license",
        data: {
          license_key: data.license_key,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        validateStatus: (status) => {
          return status < 500;
        },
      });
      if (response.status === 200) {
        props.setSnackbarState(true, "success", "認証コードを保存しました。");
        props.setLicenseActivated(true);
      } else {
        props.setSnackbarState(
          true,
          "warning",
          "認証コードを保存できませんでした。\n " + response.data.messages
        );
      }
    } catch (error) {
      props.setSnackbarState(
        true,
        "error",
        "処理中にエラーが発生しました。認証コードを保存できませんでした。"
      );
    }
  };

  return (
    <Box>
      <Box p={5}>
        {configured ? (
          <div>
            試用版の状態では、Botの発言の先頭に【試用版】の文字が入ります。
            <br></br>
            認証コードを登録すると【試用版】の文字が外れます。
          </div>
        ) : (
          "認証コードは設定後に登録できるようになります"
        )}
      </Box>
      {configured && (
        <Box pb={2}>
          現在のプラン：
          <span>{props.licenseActivated ? "製品版" : "試用版"}</span>
        </Box>
      )}
      <Box>
        <CnTextField
          name="license_key"
          type="text"
          label="認証コード"
          variant="outlined"
          rules={{ required: true }}
          disabled={!configured}
          error={errors.license_key ? true : false}
          style={{ width: "20rem" }}
        />
      </Box>
      <Box p={4}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(submitLicenseKey)}
          disabled={!configured}
        >
          登録
        </Button>
      </Box>
    </Box>
  );
};
