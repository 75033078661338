import { Box, Card, CardContent, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { Link } from "react-router-dom";

const ServiceCard = styled(Card)({
  padding: 30,
  margin: 30,
});
const ServiceLink = styled(Link)({
  color: "#000000",
  textDecoration: "none",
});

const Home: React.FC = () => {
  return (
    <Box sx={{ p: 5 }}>
      <ServiceCard>
        <ServiceLink to="appsettings/collaboflow">
          <CardContent>
            <Typography variant="h5">コラボフローコネクト</Typography>
            <Typography>
              コラボフローインスタンス同士の連携設定を行います
            </Typography>
          </CardContent>
        </ServiceLink>
      </ServiceCard>
      <ServiceCard>
        <ServiceLink to="appsettings/microsoft-teams">
          <CardContent>
            <Typography variant="h5">Microsoft Teams</Typography>
            <Typography>Microsoft Teamsとの連携設定を行います</Typography>
          </CardContent>
        </ServiceLink>
      </ServiceCard>
    </Box>
  );
};
export default Home;
