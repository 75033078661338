import { useAuth0 } from "@auth0/auth0-react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import { Box, Button, Divider, Typography } from "@mui/material";
import axios from "axios";
import FormPanel from "components/molecules/FormPanel";
import { Loader } from "components/molecules/Loader";
import CnSelectField from "components/molecules/SelectField";
import CnTextFiled from "components/molecules/TextFiled";
import { SnackbarContext } from "context/SnackbarContext";
import { fetchMySettings } from "features/collaboflow/apis/Settings";
import React, { useContext, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

const ConnectionRequest: React.FC = () => {
  const { setSnackbarState } = useContext(SnackbarContext);
  const formMethods = useForm({
    defaultValues: {
      origin_setting: "",
      connection_request_mailaddress: "",
      connection_request_comment: "",
    },
  });
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const {
    handleSubmit,
    formState: { errors },
  } = formMethods;
  const [myConnections, setMyConnections] = useState([]);
  const [progress, setProgress] = useState(true);

  /**
   * 接続許可依頼の送信
   * @param data
   */
  const sendConnectionRequest = async (data: any) => {
    const instanceName = data.origin_setting;
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_SB_AUTH0_AUDIENCE,
    });
    setProgress(true);
    await axios({
      method: "post",
      baseURL: process.env.REACT_APP_API_SB_SERVER_URL,
      url: "/admin/permission",
      data: {
        instance_name: instanceName,
        mail_address: data.connection_request_mailaddress,
        message: data.connection_request_comment,
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "content-type": "application/json",
      },
      validateStatus: (status: any) => {
        return status < 500;
      },
    })
    .then((res: any) => {
      if (res.status === 200) {
        setSnackbarState(true, "success", "接続許可依頼を送信しました");
        navigate("/appsettings/collaboflow/connection");
      } else {
        setSnackbarState(true, "error", "送信中にエラーが発生しました。");
      }
    })
    .catch((error) => {
      console.log(error, "postAdminSettings");
      setSnackbarState(true, "error", "送信中にエラーが発生しました。");
    }).finally(() => {
      setProgress(false);
    })
  };

  /**
   * 初回読み込み時処理
   */
  useEffect(() => {
    (async () => {
      setProgress(true);
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_SB_AUTH0_AUDIENCE,
      });
      const response = await fetchMySettings(accessToken);
      if (response.count > 0) {
        const data = response.data;
        const connections = data.map((data: any, index: number) => {
          return {
            key: data.pk,
            title: data.collaboflow_instance_name,
          };
        });
        await setMyConnections(connections);
      }
      setProgress(false);
    })();
  }, []);

  return (
    <FormProvider {...formMethods}>
      <Box>
        <Box
          sx={{
            display: "flex",
            padding: "1rem",
            justifyContent: "space-between",
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              navigate("../connection");
            }}
            startIcon={<ArrowBackIcon />}
          >
            戻る
          </Button>
          <Typography variant="h6" sx={{ ml: 3 }}>
            接続依頼
          </Typography>
          <Box sx={{ width: 110 }}></Box>
        </Box>
        <Divider variant="middle" />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "1rem",
          }}
        >
          <FormPanel sx={{ pt: "2rem", pl: "20rem", pr: "20rem" }}>
            <Box sx={{ mb: 5 }}>
              <Box>
                外部のコラボフローのオーナー宛てに接続許可依頼を送信します。
              </Box>
            </Box>
            <CnSelectField
              notched
              name="origin_setting"
              label="接続を依頼するコラボフロー"
              options={myConnections}
              rules={{ required: true }}
              error={errors.origin_setting ? true : false}
              helpertext={
                <span>
                  対象のコラボフローが無い場合は
                  <Button
                    variant="text"
                    onClick={() => {
                      navigate("../connection");
                    }}
                  >
                    接続の追加
                  </Button>
                  を行ってください。
                </span>
              }
            ></CnSelectField>
            <CnTextFiled
              name="connection_request_mailaddress"
              label="依頼先オーナーのメールアドレス"
              rules={{ 
                required: true,
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: "メールアドレスの形式が正しくありません。"
                }
              }}
              type="email"
              error={errors.connection_request_mailaddress ? true : false}
              helperText={errors.connection_request_mailaddress ? errors.connection_request_mailaddress.message : ""}
            />
            <CnTextFiled
              name="connection_request_comment"
              label="依頼先コメント"
              multiline
              rows={2}
              rules={{ required: true }}
              error={errors.connection_request_comment ? true : false}
            />
            <Box>
              <Button
                color="primary"
                variant="contained"
                onClick={handleSubmit(sendConnectionRequest)}
                startIcon={<SendIcon />}
              >
                依頼を送信する
              </Button>
            </Box>
          </FormPanel>
        </Box>
      </Box>
      <Loader open={progress} />
    </FormProvider>
  );
};
export default ConnectionRequest;
