import { useAuth0 } from "@auth0/auth0-react";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EmailIcon from '@mui/icons-material/Email';
import { Box, Button, Chip, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import CrDataGrid from "components/molecules/DataGrid";
import { Loader } from "components/molecules/Loader";
import { BladeTitle } from "components/organisms/BladeTitle";
import { fetchPermissions } from "features/collaboflow/apis/Permissions";
import { fetchMySettings } from "features/collaboflow/apis/Settings";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {formatLocalZonedDate} from "utils/Formatter";

const columns: GridColDef[] = [
  {
    field: "connection_name",
    headerName: "接続名",
    width: 300,
  },
  {
    field: "instance_name",
    headerName: "インスタンス名",
    width: 300,
  },
];

const externalConnectionColumns: GridColDef[] = [
  {
    field: "title",
    headerName: "接続名",
    width: 300,
  },
  {
    field: "instance_name",
    headerName: "依頼元インスタンス名",
    width: 200,
  },
  {
    field: "permission_instance",
    headerName: "許可されたインスタンス名",
    width: 200,
  },
  {
    field: "status",
    headerName: "状態",
    width: 100,
    renderCell: (params: any) => (
      <Chip
        label={params.value === "enable" ? "有効" : "無効"}
        color={params.value === "enable" ? "primary" : "default"}
        variant={"outlined"}
      />
    ),
  },
  {
    field: "request_date",
    headerName: "依頼日時",
    width: 200,
  },
];

const ConnectionSettingsPanel: React.FC = () => {
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const [rows, setRows] = useState<any>([]);
  const [externalConnectionRows, setExternalConnectionRows] = useState<any>([]);
  const [progress, setProgress] = useState(true);

  /**
   * 自分がオーナーのコラボフロー接続情報取得
   */
  const fetchMyConnections = async () => {
    setProgress(true);
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_SB_AUTH0_AUDIENCE,
    });
    const response = await fetchMySettings(accessToken);
    if (response.count > 0) {
      const data = response.data;
      const connections = data.map((data: any, index: number) => {
        return {
          id: data.pk,
          connection_name: data.name,
          instance_name: data.collaboflow_instance_name,
          status: data.status,
        };
      });
      setRows(connections);
    }
    setProgress(false);
  };

  /**
   * 外部の接続許可情報一覧を取得
   */
  const fetchExternalConnections = async () => {
    setProgress(true);
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_SB_AUTH0_AUDIENCE,
    });
    const resPermissions = await fetchPermissions(accessToken, "all");
    const data = resPermissions.data;



    let gridRows = data.map((item: any) => {
      return {
        id: item.id,
        title: item.title,
        instance_name: item.origin_instance,
        permission_instance: item.permission_instance,
        status: item.status,
        request_date:formatLocalZonedDate(item.permission.permission_request[0].request_datetime),
      };
    });
    setProgress(false);
    setExternalConnectionRows(gridRows);
  };

  /**
   * 初回読み込み時の処理
   */
  useEffect(() => {
    fetchMyConnections();
    fetchExternalConnections();
  }, []);

  return (
    <Box sx={{ height: 800, width: "100%" }}>
      <BladeTitle>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            navigate("../");
          }}
          startIcon={<ArrowBackIcon />}
        >
          戻る
        </Button>
      </BladeTitle>
      <Box sx={{ pt: "2rem", pb: "3rem" }}>
        <Button
          variant="contained"
          color="primary"
          sx={{ ml: 3 }}
          size="large"
          startIcon={<AddIcon />}
          onClick={() => {
            navigate("edit");
          }}
        >
          接続情報の追加
        </Button>
        <Button
          variant="contained"
          color="primary"
          sx={{ ml: 3 }}
          size="large"
          startIcon={<EmailIcon />}
          onClick={() => {
            navigate("external/request");
          }}
        >
          外部への接続リクエスト
        </Button>
      </Box>
      <Box>
        <Box sx={{ textAlign: "left", pl: 4, pb: 1 }}>
          <Typography>あなたが登録したコラボフロー接続情報</Typography>
        </Box>
        <CrDataGrid
          rows={rows}
          columns={columns}
          pageSize={5}
          autoHeight
          rowsPerPageOptions={[5]}
          onRowClick={(params, event) => {
            navigate(
              `edit/${params.id}?instance_name=${params.row.instance_name}`
            );
          }}
          sx={{ ml: 4, mr: 4 }}
        ></CrDataGrid>
      </Box>
      <Box>
        <Box sx={{ textAlign: "left", pl: 4, pb: 1, pt: 1 }}>
          <Typography>外部のコラボフロー接続情報</Typography>
        </Box>
        <CrDataGrid
          rows={externalConnectionRows}
          columns={externalConnectionColumns}
          pageSize={5}
          autoHeight
          rowsPerPageOptions={[5]}
          onRowClick={(params, event) => {
            navigate(
              `external/edit/${params.id}?type=send`
            );
          }}
          sx={{ ml: 4, mr: 4 }}
        ></CrDataGrid>
      </Box>
      <Loader open={progress} />
    </Box>
  );
};
export default ConnectionSettingsPanel;
