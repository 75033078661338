interface JudgeLabelTypes {
  [key: string]: string;
}

/**
 * 判定ラベル定義
 */
export const JudgeLabel: JudgeLabelTypes = {
  allow: "許可",
  deny: "拒否",
  pending: "保留中",
};
