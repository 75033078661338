import axios from "axios";

/**
 * コラボフローの経路情報一覧を取得する
 */
export const fetchCollaboflowProcesses = async (
  accessToken: string,
  instanceName: string
) => {
  const response = await axios({
    method: "get",
    baseURL: process.env.REACT_APP_API_SB_SERVER_URL,
    url: `/admin/rootid?instance_name=${instanceName}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    validateStatus: (status: any) => {
      return status < 500;
    },
  });
  return response.data;
};
