import { useAuth0 } from "@auth0/auth0-react";
import {
  BugReport as BugReportIcon,
  CheckCircle as CheckCircleIcon,
  Error as ErrorIcon,
  Info as InfoIcon,
  Warning as WarningIcon,
} from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Button, Pagination } from "@mui/material";
import {
  DataGridProps,
  GridColDef,
  gridPageCountSelector,
  gridPageSelector,
  GridRowsProp,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import axios from "axios";
import CrDataGrid from "components/molecules/DataGrid";
import { DataGridDialog } from "components/molecules/DataGridDialog";
import { Loader } from "components/molecules/Loader";
import CnSelectField from "components/molecules/SelectField";
import { BladeTitle } from "components/organisms/BladeTitle";
import { HorizonalField } from "components/organisms/HorizontalField";
import { useEffect, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { formatLocalZonedDate } from "utils/Formatter";
import { fetchMySettings } from "../apis/Settings";

interface FetchMoreLogProps {
  fetchLogs: Function;
  selectedInstance: string;
  nextPagenationKey: any;
  rowCount: number;
}

interface LogDataGirdProps extends DataGridProps {
  rows: GridRowsProp;
  setGridDialogContent: Function;
  setGridDialogOpen: Function;
  isGridDialogOpen: boolean;
  gridDialogContent: any;
  collaboflowInstanceName: string;
  setRows: Function;
}

const rows: DataGridProps[] = [];
const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "ID",
    width: 20,
    hide: true,
  },
  {
    field: "loglevel",
    headerName: "種別",
    width: 100,
    renderCell: (params: any) => (
      <span>
        {params.value === "success" && <CheckCircleIcon color="success" />}
        {params.value === "warning" && <WarningIcon color="warning" />}
        {params.value === "error" && <ErrorIcon color="error" />}
        {params.value === "info" && <InfoIcon color="info" />}
        {params.value === "debug" && <BugReportIcon color="warning" />}
      </span>
    ),
  },
  {
    field: "createdate",
    headerName: "日時",
    type: "dateTime",
    width: 200,
    renderCell: (params) => <span>{params.value}</span>,
  },
  {
    field: "info",
    headerName: "情報",
    width: 460,
  },
];

/**
 * 次のログレコード取得コンポーネント
 */
export const FetchMoreLogs: React.FC<FetchMoreLogProps> = (props) => {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  return (
    <Box
      sx={{
        p: 1,
        pr: 3,
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
      }}
    >
      {props.nextPagenationKey !== undefined && (
        <Button
          onClick={() => {
            props.fetchLogs(props.selectedInstance);
          }}
        >
          更にレコードを取得
        </Button>
      )}
      <Pagination
        count={pageCount}
        page={page + 1}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
      <Box>{props.rowCount}件</Box>
    </Box>
  );
};

const LogDataGird: React.FC = () => {
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const [myConnections, setMyConnections] = useState([]);
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [gridDialogOpen, setGridDialogOpen] = useState(false);
  const [gridDialogContent, setGridDialogContent] = useState("");
  const [progress, setProgress] = useState(false);
  const [nextPagenationKey, setNextPagenationKey] = useState();
  const formMethods = useForm({
    defaultValues: {
      instanceName: "",
    },
  });
  const { control } = formMethods;
  const selectedInstance = useWatch({
    control,
    name: "instanceName",
  });

  useEffect(() => {
    (async () => {
      /**
       * コラボフロー接続情報の取得
       */
      setProgress(true);
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_SB_AUTH0_AUDIENCE,
      });
      const response = await fetchMySettings(accessToken);
      if (response.count > 0) {
        const data = response.data;
        const connections = data.map((data: any, index: number) => {
          return {
            key: data.pk,
            title: data.collaboflow_instance_name,
          };
        });
        setMyConnections(connections);
      }
      setProgress(false);
    })();
  }, []);

  /**
   * インスタンス選択時の処理
   */
  useEffect(() => {
    (async () => {
      if (selectedInstance.length === 0) {
        setRows([]);
        setNextPagenationKey(undefined);
        return;
      }
      await fetchLogs(selectedInstance);
    })();
  }, [selectedInstance]);

  /**
   * ログ一覧の取得
   */
  const fetchLogs = async (instanceName: string) => {
    setProgress(true);
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_SB_AUTH0_AUDIENCE,
    });
    const lastEvaluatedKeyToken =
      nextPagenationKey !== undefined
        ? encodeURIComponent(JSON.stringify(nextPagenationKey))
        : "";
    const response = await axios({
      method: "get",
      baseURL: process.env.REACT_APP_API_SB_SERVER_URL,
      url: `/admin/log?instance_name=${instanceName}`,
      params: {
        last_evaluated_key: lastEvaluatedKeyToken,
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    let items = response.data.data;
    const resEvaluatedKey = response.data.LastEvaluatedKey;
    setNextPagenationKey(resEvaluatedKey);
    let records = items.map((item: any, index: number) => {
      return {
        id: index,
        createdate: formatLocalZonedDate(item.create_at),
        loglevel: item.log_level,
        info: item.info.message,
      };
    });
    const addedRows = records.concat(rows);
    setRows(addedRows);
    setProgress(false);
  };

  /**
   * ログ詳細ダイアログクローズ
   */
  const closeDataGridDialog = () => {
    setGridDialogOpen(false);
  };
  let rowCount = rows.length;

  return (
    <FormProvider {...formMethods}>
      <Box sx={{ width: "100%" }}>
        <BladeTitle>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              navigate("../");
            }}
            startIcon={<ArrowBackIcon />}
          >
            戻る
          </Button>
        </BladeTitle>
        <Box sx={{ mt: 2, ml: 4, mr: 4 }}>
          <HorizonalField>
            <CnSelectField
              notched
              name="instanceName"
              label="コラボフロー接続名"
              options={myConnections}
              sx={{ width: "20rem" }}
            ></CnSelectField>
          </HorizonalField>
          <CrDataGrid
            rows={rows}
            columns={columns}
            pageSize={100}
            height={400}
            onRowClick={(params, event) => {
              setGridDialogContent(params.row.info);
              setGridDialogOpen(true);
            }}
            components={{
              Footer: FetchMoreLogs,
            }}
            componentsProps={{
              footer: {
                fetchLogs,
                selectedInstance,
                nextPagenationKey,
                rowCount,
              },
            }}
          ></CrDataGrid>
        </Box>
      </Box>
      <DataGridDialog
        open={gridDialogOpen}
        selectedValue={gridDialogContent}
        title="ログの詳細"
        onClose={closeDataGridDialog}
      ></DataGridDialog>
      <Loader open={progress} />
    </FormProvider>
  );
};
export default LogDataGird;
