import React from "react";
import { useFormContext } from "react-hook-form";

interface HiddenFieldProps {
  id: string;
}

/**
 * 標準HiddenFieldコンポーネント
 * @param props
 * @returns
 */
const HiddenField: React.FC<HiddenFieldProps> = (props) => {
  const { register } = useFormContext();
  return (
    <div>
      <input type="hidden" id={props.id} {...register(props.id)} />
    </div>
  );
};

export default HiddenField;
