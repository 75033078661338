import { useAuth0 } from "@auth0/auth0-react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Button, Chip, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import axios from "axios";
import CrDataGrid from "components/molecules/DataGrid";
import { Loader } from "components/molecules/Loader";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { formatLocalZonedDate } from "utils/Formatter";
import { JudgeLabel } from "../../../../const/labels";

const reciveRequestColumns: GridColDef[] = [
  {
    field: "id",
    headerName: "ID",
    hide: true,
  },
  {
    field: "title",
    headerName: "接続名",
    width: 300,
  },
  {
    field: "instance_name",
    headerName: "依頼元インスタンス名",
    width: 200,
  },
  {
    field: "permission_instance",
    headerName: "許可されたインスタンス名",
    width: 200,
  },
  {
    field: "status",
    headerName: "状態",
    width: 100,
    renderCell: (params: any) => (
      <Chip
        label={params.value === "enable" ? "有効" : "無効"}
        color={params.value === "enable" ? "primary" : "default"}
        variant={"outlined"}
      />
    ),
  },
  {
    field: "judge_status",
    headerName: "判定",
    width: 100,
    renderCell: (params: any) => (
      <Chip label={JudgeLabel[params.value]} variant={"outlined"} />
    ),
  },
  {
    field: "judged_date",
    headerName: "判定日",
    width: 150,
  },
];

const ExternalConnection: React.FC = () => {
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const [reciveRequestRows, setReciveRequestRows] = useState<any>([]);
  const [progress, setProgress] = useState(true);

  /** 初回読み込み時の処理 */
  useEffect(() => {
    (async () => {
      setProgress(true);
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_SB_AUTH0_AUDIENCE,
      });
      const response = await axios({
        method: "get",
        baseURL: process.env.REACT_APP_API_SB_SERVER_URL,
        url: `/admin/permissions?type=recieve`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "content-type": "application/json",
        },
        validateStatus: (status: any) => {
          return status < 500;
        },
      });
      setProgress(false);
      const resData = response.data.data;
      const rows = resData.map((item: any) => {
        return {
          id: item.id,
          title: item.title,
          instance_name: item.origin_instance,
          permission_instance: item.permission_instance,
          status: item.status,
          judge_status: item.permission.permission_request[0].status,
          judged_date: formatLocalZonedDate(
            item.permission.permission_request[0].judge_datetime
          ),
        };
      });
      setReciveRequestRows(rows);
    })();
  }, []);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          padding: "1rem",
          justifyContent: "space-between",
        }}
      >
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            navigate("/appsettings/collaboflow");
          }}
          startIcon={<ArrowBackIcon />}
        >
          戻る
        </Button>
        <Typography variant="h6" sx={{ ml: 3 }}>
          接続許可リクエスト
        </Typography>
        <Box sx={{ width: 110 }}></Box>
      </Box>
      <Box>
        <Box sx={{ textAlign: "left", pl: 4, pb: 1, pt: 1 }}>
          <CrDataGrid
            rows={reciveRequestRows}
            columns={reciveRequestColumns}
            pageSize={5}
            autoHeight
            rowsPerPageOptions={[5]}
            onRowClick={(params, event) => {
              navigate(
                `edit/${params.id}`
              );
            }}
            sx={{ ml: 4, mr: 4 }}
          ></CrDataGrid>
        </Box>
      </Box>
      <Loader open={progress} />
    </Box>
  );
};
export default ExternalConnection;
