import { Alert, Snackbar, SnackbarCloseReason } from "@mui/material";
import { useContext } from "react";
import { SnackbarContext } from "../../context/SnackbarContext";

export const Notification = () => {
  const { snackbarState, setSnackbarState } = useContext(SnackbarContext);
  const handleClose = (
    event: Event | React.SyntheticEvent,
    reason?: SnackbarCloseReason
  ) => {
    setSnackbarState(false, snackbarState.type, "");
    return false;
  };
  const snackBarMessage = snackbarState.message;
  const messages = Array.isArray(snackBarMessage)
    ? snackBarMessage
    : snackBarMessage.split(/\r\n|\n|\r/);
  const breakedMessage = messages.map((line: string, key: number) => (
    <span key={key}>
      {line}
      <br />
    </span>
  ));

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        autoHideDuration={5000}
        onClose={handleClose}
        open={snackbarState.isOpen}
      >
        <Alert severity={snackbarState.type} onClose={handleClose}>
          {breakedMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};
