import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CheckIcon from "@mui/icons-material/Check";
import { Box, Button } from "@mui/material";
import CnTextField from "components/molecules/TextFiled";
import { BladeTitle } from "components/organisms/BladeTitle";
import { useNavigate } from "react-router-dom";

const LicenseRegistrationPanel: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Box>
      <BladeTitle>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            navigate("../");
          }}
          startIcon={<ArrowBackIcon />}
        >
          戻る
        </Button>
      </BladeTitle>
      <Box p={4}>
        <CnTextField
          name="license_key"
          type="text"
          label="認証コード"
          variant="outlined"
          style={{ width: "20rem" }}
        />
      </Box>
      <Box pb={8}>
        <Button variant="contained" color="primary" startIcon={<CheckIcon />}>
          登録
        </Button>
      </Box>
    </Box>
  );
};
export default LicenseRegistrationPanel;
