import { useAuth0 } from "@auth0/auth0-react";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Typography,
} from "@mui/material";
import axios from "axios";
import FormPanel from "components/molecules/FormPanel";
import CnSelectField from "components/molecules/SelectField";
import SelectFieldRequest from "components/molecules/SelectFieldRequest";
import { SnackbarContext } from "context/SnackbarContext";
import { fetchCollaboflowProcesses } from "features/collaboflow/apis/Collaboflow";
import { fetchMySettings } from "features/collaboflow/apis/Settings";
import React, { useContext, useEffect, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { formatLocalZonedDate } from "utils/Formatter";
import { Loader } from "components/molecules/Loader";

/**
 * 許可する経路一覧に表示する経路
 */
export type OptionRoute = {
  key: number;
  title: string;
};

/**
 * 選択した許可する経路経路
 */
export type RouteToGivePermisson = {
  route_id: number;
  route_name: string;
};

interface PermissionRequestParam {
  collaboflow_to_give_permission: string;
  collaboflow_to_give_permission_value: string;
  routes_to_give_permission: RouteToGivePermisson[];
}

const PermissionRequest: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const [permissionRequest, setPermissionRequest] = useState({
    origin_instance: "",
    permission: {
      permission_request: [{ message: "", request_datetime: "" }],
    },
  });
  const [requestMessege, setRequestMessege] = useState();
  const [progress, setProgress] = useState(false);
  const [mySettings, setMySettings] = useState([]);
  const [routes, setRoutes] = useState([]);
  const { setSnackbarState } = useContext(SnackbarContext);
  const formMethods = useForm<PermissionRequestParam>({
    defaultValues: {
      collaboflow_to_give_permission: "",
      collaboflow_to_give_permission_value: "",
      routes_to_give_permission: [],
    },
  });
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = formMethods;

  const selectedCollaboflow = useWatch({
    control,
    name: "collaboflow_to_give_permission",
  });

  const selectedRotues = useWatch({
    control,
    name: "routes_to_give_permission",
  });

  const [isEditing, setIsEditing] = useState(false);
  const [queryParams] = useSearchParams();
  const backUrl = queryParams.get("back") === "true" ? "/appsettings/collaboflow/connection/external" : null;

  /**
   * 接続許可依頼の詳細を取得する
   */
  const fetchPermissionRequest = async () => {
    setProgress(true);
    const id = params.id;
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_SB_AUTH0_AUDIENCE,
    });
    const response = await axios({
      method: "get",
      baseURL: process.env.REACT_APP_API_SB_SERVER_URL,
      url: `/admin/permission/${id}?type=recieve`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "content-type": "application/json",
      },
      validateStatus: (status: any) => {
        return status < 500;
      },
    });

    if (!response.data.data) {
      //データが存在しない場合は、エラーメッセージを取得する
      const resMessage: any = response.data.message;
      setRequestMessege(resMessage);
    }

    const resData: any = response.data.data;
    setPermissionRequest(resData);

    // 登録済みの接続許可リクエストがあれば、初期表示する
    if (resData.permission_instance !== undefined && resData.permission_instance.length > 0) {
      setValue("collaboflow_to_give_permission", resData.permission_instance);
      setIsEditing(true);

      // 登録済みの許可済み経路
      const permissionRoutes = resData.permission.permission_route;
      if (permissionRoutes !== undefined && permissionRoutes.length > 0) {
        setValue("routes_to_give_permission", permissionRoutes);
      }
    }

    setProgress(false);
  };

  /**
   * 受信者による外部接続情報の更新
   */
  const putPermissionRequestByRecipient = async () => {
    setProgress(true);
    const id = params.id;
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_SB_AUTH0_AUDIENCE,
    });
    const response = await axios({
      method: "put",
      baseURL: process.env.REACT_APP_API_SB_SERVER_URL,
      url: `/admin/permission/${id}`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "content-type": "application/json",
      },
      data: {
        type: "recieve",
      },
      validateStatus: (status: any) => {
        return status < 500;
      },
    });
    if (response.data.status === "success") {
      console.log("接続許可依頼:recipientに受信者の情報が登録されました");
    }
    setProgress(false);
  };

  /**
   * コラボフロー経路情報の取得
   */
  const fetchCollaboflowRoutes = () => {
    if (selectedCollaboflow.length === 0) {
      setValue("routes_to_give_permission", []);
      setRoutes([]);
      return;
    }

    (async () => {
      setProgress(true);
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_SB_AUTH0_AUDIENCE,
      });
      const resData = await fetchCollaboflowProcesses(
        accessToken,
        selectedCollaboflow
      );
      if (resData.status === "error") {
        setSnackbarState(true, "error", resData.messages.join("\n"));
        return;
      }
      // コラボフロー経路情報をプルダウンにセット
      const records = resData.data.records;
      if (records.length > 0) {
        const routes = records.map((data: any, index: number) => {
          return {
            key: data.id,
            title: data.title,
          };
        });
        
        setRoutes(routes);
      }
      setProgress(false);
    })();

    return;
  };

  /**
   * 承認の実行
   */
  const handleAccept = async (data: PermissionRequestParam) => {
    const id = params.id;
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_SB_AUTH0_AUDIENCE,
    });

    const response = await axios({
      method: "put",
      baseURL: process.env.REACT_APP_API_SB_SERVER_URL,
      url: `/admin/permission/${id}`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "content-type": "application/json",
      },
      data: {
        type: "accept",
        instanceName: data.collaboflow_to_give_permission,
        routes: data.routes_to_give_permission,
      },
      validateStatus: (status: any) => {
        return status < 500;
      },
    });
    setProgress(false);
    if (response.status === 400) {
      setSnackbarState(
        true,
        "error",
        "不正なリクエストです。承認は回避されました"
      );
      return;
    }
    setSnackbarState(true, "success", "リクエストを承認しました。");
    navigate("/appsettings/collaboflow/connection/external");
  };

  /**
   * 却下の実行
   */
  const handleReject = async () => {
    const id = params.id;
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_SB_AUTH0_AUDIENCE,
    });
    const response = await axios({
      method: "put",
      baseURL: process.env.REACT_APP_API_SB_SERVER_URL,
      url: `/admin/permission/${id}`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "content-type": "application/json",
      },
      data: {
        type: "reject",
      },
      validateStatus: (status: any) => {
        return status < 500;
      },
    });
    setProgress(false);
    if (response.status === 400) {
      setSnackbarState(
        true,
        "error",
        "不正なリクエストです。却下は回避されました"
      );
      return;
    }
    setSnackbarState(true, "success", "リクエストを却下しました。");
    navigate("/appsettings/collaboflow/connection/external");
  };

  /**
   * 初回読み込み時の処理
   */
  useEffect(() => {
    putPermissionRequestByRecipient();
    fetchPermissionRequest();
    /**
     * コラボフロー接続情報の取得
     */
    (async () => {
      setProgress(true);
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_SB_AUTH0_AUDIENCE,
      });
      const response = await fetchMySettings(accessToken);
      if (response.count > 0) {
        const data = response.data;
        const connections = data.map((data: any, index: number) => {
          return {
            key: data.pk,
            title: data.collaboflow_instance_name,
          };
        });
        setMySettings(connections);
      }
      setProgress(false);
    })();
  }, []);

  /**
   * 許可するコラボフロー切り替え時の処理
   */
  useEffect(() => {
    fetchCollaboflowRoutes();

    // 選択済みの経路一覧を初期化
    setValue("routes_to_give_permission", []);
  }, [selectedCollaboflow]);

  return (
    <>
      {permissionRequest && (
        //接続許可リクエストが存在する場合の表示

        <FormProvider {...formMethods}>
          <Box
            sx={{
              display: "flex",
              padding: "1rem",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Box
            sx={{
              display: "flex",
              padding: "1rem",
              justifyContent: "space-between",
            }}
            >
              {backUrl !== null ? (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => navigate(backUrl)}
                  startIcon={<ArrowBackIcon />}
                >
                  戻る
                </Button>
              ) : (
                <Box sx={{ width: 110 }}></Box>
              )}
              <Typography sx={{ ml: 3 }} variant="h6">接続許可依頼</Typography>
              <Box sx={{ width: 110 }}></Box>
            </Box>
            <Divider />
            <Box sx={{ ml: 30, mr: 30 }}>
              <Box sx={{ pt: 3 }}>
                あなたの管理するコラボフローへの接続許可依頼が届いています
              </Box>
              <Box sx={{ pt: 3, fontWeight: "bold" }}>
                接続元のコラボフロー：{permissionRequest.origin_instance}
              </Box>
              <Box sx={{ mt: 2, ml: 6, mr: 6 }}>
                <Card>
                  <CardContent>
                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text.secondary"
                      gutterBottom
                    >
                      接続元からのメッセージ
                    </Typography>
                    <Typography sx={{ p: 1 }}>
                      {
                        permissionRequest.permission.permission_request[0]
                          .message
                      }
                    </Typography>
                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text.secondary"
                      gutterBottom
                    >
                      送信日時：
                      {formatLocalZonedDate(
                        permissionRequest.permission.permission_request[0]
                          .request_datetime
                      )}
                    </Typography>
                    <Loader open={progress} />
                  </CardContent>
                </Card>
              </Box>

              <Box sx={{ pt: 3 }}>
                接続を許可する場合は、許可するコラボフローと、許可する経路を選択してください
              </Box>
              <Box>
                <FormPanel>
                  <CnSelectField
                    notched
                    name="collaboflow_to_give_permission"
                    label="許可するコラボフロー"
                    options={mySettings}
                    rules={{ required: true }}
                    error={errors.collaboflow_to_give_permission ? true : false}
                    disabled={isEditing}
                  ></CnSelectField>
                </FormPanel>
                <FormPanel>
                  <SelectFieldRequest
                    notched
                    name="routes_to_give_permission"
                    label="許可する経路"
                    routes={routes}
                    selectedRouteIdList={selectedRotues.map((r) => r.route_id)}
                    rules={{ required: true }}
                    error={errors.routes_to_give_permission ? true : false}
                  ></SelectFieldRequest>
                </FormPanel>
                <Loader open={progress} />
                <Box>
                  {!isEditing && (
                    <Button
                      variant="contained"
                      color="warning"
                      startIcon={<CloseIcon />}
                      onClick={handleReject}
                    >
                      却下する
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    startIcon={<CheckIcon />}
                    sx={{ ml: 2 }}
                    onClick={handleSubmit(handleAccept)}
                  >
                    承認する
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </FormProvider>
      )}

      {!permissionRequest && (
        //接続許可リクエストが存在しない場合の表示

        <FormProvider {...formMethods}>
          <Box
            sx={{
              display: "flex",
              padding: "1rem",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Box sx={{ p: 2 }}>
              <Typography variant="h6">接続許可依頼</Typography>
            </Box>
            <Divider variant="middle" />
            <Box sx={{ pt: 3 }}>{requestMessege}</Box>
            <Box sx={{ pt: "2rem", pb: "3rem", height: 800, width: "100%" }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  navigate("../");
                }}
                startIcon={<ArrowBackIcon />}
              >
                戻る
              </Button>
            </Box>
          </Box>
        </FormProvider>
      )}
    </>
  );
};
export default PermissionRequest;
