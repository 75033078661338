import { useAuth0 } from "@auth0/auth0-react";
import { Box, Button } from "@mui/material";
import axios from "axios";
import React from "react";

interface WebhookURLPanelProps {
  setSnackbarState: Function;
  settingTablekey: string;
}

export const WebhookURLPanel: React.FC<WebhookURLPanelProps> = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  let configured = props.settingTablekey.length > 0 ? true : false;
  /**
   * WebhookURLのコピー
   */
  const copyWebhookToClipboard = async () => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_TEAMS_AUTH0_AUDIENCE,
    });
    let responseData: any = {};
    try {
      const response = await axios({
        method: "get",
        baseURL: process.env.REACT_APP_API_SERVER_URL,
        url: "/admin/settings/webhook",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        validateStatus: (status) => {
          return status < 500;
        },
      });
      responseData = response.data;
    } catch (error) {
      props.setSnackbarState(
        true,
        "error",
        "WebhookURLの取得中にエラーが発生しました"
      );
      return;
    }
    if (responseData.status === "success") {
      try {
        const webhookUrl = responseData.data.url;
        await navigator.clipboard.writeText(webhookUrl);
        props.setSnackbarState(true, "success", "WebhookURLをコピーしました");
      } catch (error: any) {
        props.setSnackbarState(
          true,
          "error",
          `WebhookURLのコピーに失敗しました ${error && error.message}`
        );
      }
    } else {
      props.setSnackbarState(true, "error", "WebhookURLの取得に失敗しました");
    }
  };

  return (
    <Box>
      <Box sx={{ padding: "2rem" }}>
        {configured
          ? "コラボフロー側の経路に設定するWebhookURLをコピーできます"
          : "Webhookは設定後にコピーできるようになります"}
      </Box>
      <Box sx={{ paddingTop: "0", paddingBottom: "4rem" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={copyWebhookToClipboard}
          disabled={!configured}
        >
          WebhookURLのコピー
        </Button>
      </Box>
    </Box>
  );
};
