import { Backdrop, CircularProgress } from "@mui/material";
import React from "react";

interface LoaderProps {
  open: boolean;
}

export const Loader: React.FC<LoaderProps> = (props) => {
  return (
    <Backdrop
      sx={{ color: "#FFFFFF", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={props.open}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};
