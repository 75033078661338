import {
  Box,
  Button,
  Divider,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useContext, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { setTabProps, TabPanel } from "../../../components/molecules/TabPanel";
import { SnackbarContext } from "../../../context/SnackbarContext";
import { ConnectionSettingsPanel } from "../components/ConnectionSettingsPanel";
import { DisconnectDialog } from "../components/DisconnectDialog";
import { LicenseRegistrationPanel } from "../components/LicenseRegistrationPanel";
import { LogDataGird } from "../components/LogDataGird";
import { WebhookURLPanel } from "../components/WebhookURLPanel";

const TeamsSettings = styled("div")({
  width: 800,
});

/**
 * Teams設定画面
 * @returns
 */
const Main = () => {
  const { setSnackbarState } = useContext(SnackbarContext);
  const [value, setTabIndex] = useState(0);
  const [rows, setRows] = useState([]);
  const [isGridDialogOpen, setGridDialogOpen] = useState(false);
  const [gridDialogContent, setGridDialogContent] = useState("");
  const [isDisconnectDialogOpen, setDisconnectDialogOpen] = useState(false);
  const [settingTablekey, setSettingTablekey] = useState("");
  const [collaboflowInstanceName, setCollaboflowInstanceName] = useState("");
  const [licenseActivated, setLicenseActivated] = useState(false);
  const formMethods = useForm({
    defaultValues: {
      collaboflow_instance_name: "",
      collaboflow_user_id: "",
      collaboflow_api_key: "",
      collaboflow_app_cd: 1,
      teams_tenant_id: "",
      license_key: "",
      enable_jubdge_ui: "true",
    },
  });

  /**
   * タブ切り替え処理
   * @param {*} event
   * @param {*} newValue
   */
  const changeTab = (event: any, newValue: number) => {
    setTabIndex(newValue);
  };

  const openDisconnectDialog = () => {
    setDisconnectDialogOpen(true);
  };

  return (
    <div>
      <FormProvider {...formMethods}>
        <form noValidate autoComplete="off">
          <Paper variant="outlined">
            <Box p={2}>
              <Typography variant="h5">Microsoft Teams</Typography>
            </Box>
            <Divider variant="middle" />
            <Tabs value={value} onChange={changeTab}>
              <Tab label="設定" {...setTabProps(0)}></Tab>
              <Tab label="Webhook" {...setTabProps(1)}></Tab>
              <Tab label="認証コード" {...setTabProps(2)}></Tab>
              <Tab label="ログ" {...setTabProps(3)}></Tab>
            </Tabs>
            <TabPanel index={value} value={0}>
              <TeamsSettings>
                <ConnectionSettingsPanel
                  collaboflowInstanceName={collaboflowInstanceName}
                  setSnackbarState={setSnackbarState}
                  setSettingTablekey={setSettingTablekey}
                  settingTablekey={settingTablekey}
                  setLicenseActivated={setLicenseActivated}
                  setCollaboflowInstanceName={setCollaboflowInstanceName}
                ></ConnectionSettingsPanel>
              </TeamsSettings>
            </TabPanel>
            <TabPanel index={value} value={1}>
              <TeamsSettings>
                <WebhookURLPanel
                  setSnackbarState={setSnackbarState}
                  settingTablekey={settingTablekey}
                ></WebhookURLPanel>
              </TeamsSettings>
            </TabPanel>
            <TabPanel index={value} value={2}>
              <TeamsSettings>
                <LicenseRegistrationPanel
                  setSnackbarState={setSnackbarState}
                  settingTablekey={settingTablekey}
                  licenseActivated={licenseActivated}
                  setLicenseActivated={setLicenseActivated}
                ></LicenseRegistrationPanel>
              </TeamsSettings>
            </TabPanel>
            <TabPanel index={value} value={3}>
              <TeamsSettings>
                <LogDataGird
                  columns={[]}
                  rows={rows}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                  onRowClick={(params, event) => {
                    setGridDialogContent(params.row.info);
                    setGridDialogOpen(true);
                  }}
                  setRows={setRows}
                  setGridDialogOpen={setGridDialogOpen}
                  setGridDialogContent={setGridDialogContent}
                  isGridDialogOpen={isGridDialogOpen}
                  gridDialogContent={gridDialogContent}
                  collaboflowInstanceName={collaboflowInstanceName}
                ></LogDataGird>
              </TeamsSettings>
            </TabPanel>
            <Divider variant="middle" />
            <Box
              sx={{
                pt: 2,
                pr: 3,
                pl: 3,
                pb: 2,
                display: "flex",
                flexDirection: "row-reverse",
              }}
            >
              <Button
                color="secondary"
                variant="outlined"
                onClick={openDisconnectDialog}
                disabled={settingTablekey.length === 0}
              >
                連携の解除
              </Button>
              <DisconnectDialog
                open={isDisconnectDialogOpen}
                setSnackbarState={setSnackbarState}
                setDisconnectDialogOpen={setDisconnectDialogOpen}
                setCollaboflowInstanceName={setCollaboflowInstanceName}
              ></DisconnectDialog>
            </Box>
          </Paper>
        </form>
      </FormProvider>
    </div>
  );
};
export default Main;
