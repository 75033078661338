import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
  SxProps,
  Checkbox,
  ListItemText,
} from "@mui/material";
import {
  OptionRoute,
  RouteToGivePermisson,
} from "features/collaboflow/components/ConnectionSettings/PermissionRequest";
import {
  Path,
  RegisterOptions,
  useController,
  useFormContext,
} from "react-hook-form";
import HiddenField from "./HiddenField";

export type CrSelectChangeEvent = SelectChangeEvent;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

/**
 * 選択された経路のIDをキーにして、経路情報を取得します
 * @param {number} roureId Selectコンポーネントで選択された経路のID
 * @param {{key: number; title: string;}} routes Selectコンポーネントで使用されるデータ配列
 * @returns {{key: number; title: string;}} 経路情報
 */
export const getRouteByRouteId = (
  roureId: number,
  routes: OptionRoute[]
): OptionRoute | null => {
  if (roureId === undefined) {
    return null;
  }
  const route = routes.find((route: any) => route.key === roureId);
  if (route === undefined) {
    return null;
  }
  return route;
};

/**
 * 選択された経路のIDから、経路名を取得する
 */
const renderTitle = (selected: any, routes: any): string => {
  // routeTitles:string[]
  const routeTitles: string[] = [];
  for (let selectedId of selected) {
    // renderRoute{id:number;title:string}
    const renderRoute = getRouteByRouteId(selectedId, routes);
    if (renderRoute !== null) {
      routeTitles.push(renderRoute.title);
    }
  }
  return routeTitles.join(" ・ ");
};

interface CustomSelectProps extends SelectProps {
  name: Path<any>;
  valueFieldName?: string;
  routes: Array<any>;
  selectedRouteIdList: number[];
  variant?: any;
  hideLabel?: boolean;
  helpertext?: React.ReactNode;
  formControlSx?: SxProps;
  rules?: Omit<RegisterOptions, "setValueAs">;
}

export const SelectFieldRequest: React.FC<CustomSelectProps> = (props) => {
  const {
    name,
    routes,
    variant,
    formControlSx,
    value,
    rules,
    valueFieldName,
    selectedRouteIdList,
    ...selectFieldProps
  } = props;
  const inputLabelId = name + "_label";
  const { control, setValue } = useFormContext();
  const hiddenName = valueFieldName || name + "_value";
  const {
    field: { ref, ...rest },
  } = useController({ name, rules, control });
  const formControlSxProps = formControlSx || { mb: 3 };

  const handleChange = (event: SelectChangeEvent<any>) => {
    // 選択された経路のIDリスト
    const selectedIds = event.target?.value as number[];

    // routes_to_give_permission に格納する形に変換する
    const selectedRoutes: RouteToGivePermisson[] = [];
    for (let routeId of selectedIds) {
      const route = getRouteByRouteId(routeId, routes);
      if (route !== null) {
        const formattedRoute: RouteToGivePermisson = {
          route_id: route.key,
          route_name: route.title,
        };
        selectedRoutes.push(formattedRoute);
      }
    }

    setValue("routes_to_give_permission", selectedRoutes);
  };

  return (
    <FormControl sx={formControlSxProps}>
      {!props.hideLabel && (
        <InputLabel shrink id={inputLabelId}>
          {props.label}
        </InputLabel>
      )}
      <Select
        id={name}
        labelId={inputLabelId}
        inputRef={ref}
        variant={variant || "outlined"}
        displayEmpty
        {...rest}
        {...selectFieldProps}
        onChange={handleChange}
        multiple
        value={selectedRouteIdList}
        renderValue={(selected: any) => renderTitle(selected, routes)}
        MenuProps={MenuProps}
      >
        <MenuItem key="Null">未選択</MenuItem>
        {routes.map((route) => (
          <MenuItem value={route.key} key={route.key}>
            <Checkbox checked={selectedRouteIdList.indexOf(route.key) > -1} />
            <ListItemText primary={route.title} />
          </MenuItem>
        ))}
      </Select>
      {props.helpertext && <FormHelperText>{props.helpertext}</FormHelperText>}
      <HiddenField id={hiddenName}></HiddenField>
    </FormControl>
  );
};

export default SelectFieldRequest;
