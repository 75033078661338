import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

export interface DataGridDialogProps {
  open: boolean;
  title: string;
  selectedValue: string;
  onClose: (value: string) => void;
}

export const DataGridDialog: React.FC<DataGridDialogProps> = ({
  open,
  title,
  selectedValue,
  onClose,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        <DialogContentText>
          {selectedValue.split("\n").map((item, index) => {
            return (
              <span
                style={{ marginRight: 3 }}
                key={`datagrid-dialog-info-row${index}`}
              >
                {item}
              </span>
            );
          })}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};
