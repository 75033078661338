import { Box, Divider, Paper, Typography } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { Outlet } from "react-router-dom";

/**
 * コラボフロー連携設定画面
 * @returns
 */
const Main = () => {
  const formMethods = useForm();
  return (
    <Box sx={{ width: 1200 }}>
      <FormProvider {...formMethods}>
        <form noValidate autoComplete="off">
          <Paper variant="outlined">
            <Box p={2}>
              <Typography variant="h6">コラボフローコネクト</Typography>
            </Box>
            <Divider variant="middle" />
            <Outlet></Outlet>
            <Divider variant="middle" />
            <Box
              sx={{
                pt: 2,
                pr: 3,
                pl: 3,
                pb: 2,
                display: "flex",
                flexDirection: "row-reverse",
              }}
            ></Box>
          </Paper>
        </form>
      </FormProvider>
    </Box>
  );
};
export default Main;
