import { Box, BoxProps } from "@mui/material";
import { styled } from "@mui/material/styles";

const HorizonalFieldBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-end",
});

export const HorizonalField: React.FC<BoxProps> = (props) => {
  return <HorizonalFieldBox {...props}>{props.children}</HorizonalFieldBox>;
};
