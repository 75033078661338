import { useAuth0 } from "@auth0/auth0-react";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import axios, { AxiosRequestConfig } from "axios";
import React, { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import FormPanel from "../../../components/molecules/FormPanel";
import { Loader } from "../../../components/molecules/Loader";
import CnTextField from "../../../components/molecules/TextFiled";
import { fetchAuth0UserInfo } from "../../../utils/Auth0";
import { getProperty } from "../../../utils/Utility";

interface ConnectionSettingsPanelProps {
  collaboflowInstanceName: string;
  setSnackbarState: Function;
  setSettingTablekey: Function;
  settingTablekey: string;
  setLicenseActivated: Function;
  setCollaboflowInstanceName: Function;
}

export const ConnectionSettingsPanel: React.FC<ConnectionSettingsPanelProps> = (
  props
) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useFormContext();

  const [progress, setProgress] = useState(true);
  const { user, getAccessTokenSilently } = useAuth0();
  const auth0UserInfo = fetchAuth0UserInfo(user);
  let collaboflowInstanceName = props.collaboflowInstanceName;

  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_TEAMS_AUTH0_AUDIENCE,
      });
      const response = await axios({
        method: "get",
        baseURL: process.env.REACT_APP_API_SERVER_URL,
        url: "/admin/settings",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        validateStatus: (status: any) => {
          return status < 500;
        },
      });
      if (response.data.count > 0) {
        const data = response.data.data;

        props.setCollaboflowInstanceName(data.collaboflow_instance_name);
        setValue("collaboflow_instance_name", data.collaboflow_instance_name);
        setValue("collaboflow_user_id", data.settings.collaboflow.user_id);
        setValue("collaboflow_api_key", data.settings.collaboflow.api_key);
        setValue("teams_tenant_id", data.teams_tenant_id);
        setValue("license_key", data.licenseKey);
        setValue(
          "enable_jubdge_ui",
          getProperty(data, "settings.policy.enable_jubdge_ui") || "true"
        );
        if (data.licenseKey !== undefined) {
          props.setLicenseActivated(true);
        }
        props.setSettingTablekey(data.pk);
      } else {
        props.setSettingTablekey("");
      }
      setProgress(false);
    })();
  }, [collaboflowInstanceName]);

  /**
   * ステータスに応じたAxiosConfigの変更
   * @param settingTablekey
   * @param data
   * @returns
   */
  const getAxiosConfig = async (settingTablekey: string, data: any) => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_TEAMS_AUTH0_AUDIENCE,
    });
    // 設定が既にある場合はput
    if (settingTablekey.length > 0) {
      return {
        method: "put",
        baseURL: process.env.REACT_APP_API_SERVER_URL,
        url: "/admin/settings",
        data: {
          pk: settingTablekey,
          user_id: data.collaboflow_user_id,
          api_key: data.collaboflow_api_key,
          app_cd: data.collaboflow_app_cd,
          enable_jubdge_ui: data.enable_jubdge_ui,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        validateStatus: (status: any) => {
          return status < 500;
        },
      };
    } else {
      // 未設定の場合はpost
      return {
        method: "post",
        baseURL: process.env.REACT_APP_API_SERVER_URL,
        url: "/admin/settings",
        data: {
          collaboflow_instance_name: data.collaboflow_instance_name,
          user_id: data.collaboflow_user_id,
          api_key: data.collaboflow_api_key,
          app_cd: data.collaboflow_app_cd,
          tenant_id: data.teams_tenant_id,
          enable_jubdge_ui: data.enable_jubdge_ui,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        validateStatus: (status: any) => {
          return status < 500;
        },
      };
    }
  };

  /**
   * フォームのSubmit処理
   * @param {*} data
   */
  const submitForm = async (data: any) => {
    setProgress(true);
    // 未設定の場合はpost、既に設定値が存在する場合はupdate
    const config: AxiosRequestConfig = await getAxiosConfig(
      props.settingTablekey,
      data
    );
    axios(config)
      .then((res) => {
        if (res.status === 200) {
          props.setSnackbarState(true, "success", "設定を保存しました。");
          props.setCollaboflowInstanceName(data.collaboflow_instance_name);
        } else {
          props.setSnackbarState(
            true,
            "warning",
            "設定を保存できませんでした。\n " + res.data.messages
          );
        }
      })
      .catch((error) => {
        props.setSnackbarState(
          true,
          "error",
          "処理中にエラーが発生しました。設定を保存できませんでした。"
        );
      })
      .finally(() => {
        setProgress(false);
      });
  };
  return (
    <FormPanel>
      <CnTextField
        name="collaboflow_instance_name"
        type="text"
        label="コラボフローインスタンス名"
        variant="outlined"
        rules={{ required: true }}
        inputProps={{
          readOnly: props.settingTablekey.length > 0 ? true : false,
        }}
        disabled={props.settingTablekey.length > 0 ? true : false}
        error={errors.collaboflow_instance_name ? true : false}
      />
      <CnTextField
        name="collaboflow_user_id"
        type="text"
        label="コラボフロー接続ユーザーID"
        variant="outlined"
        rules={{ required: true }}
        error={errors.collaboflow_user_id ? true : false}
      />
      <CnTextField
        name="collaboflow_api_key"
        type="text"
        label="コラボフローAPIキー"
        variant="outlined"
        rules={{ required: true }}
        error={errors.collaboflow_api_key ? true : false}
      />
      <CnTextField
        name="collaboflow_app_cd"
        type="number"
        label="コラボフローAPPCD"
        variant="outlined"
        rules={{ required: true }}
        error={errors.collaboflow_app_cd ? true : false}
      />
      <CnTextField
        name="teams_tenant_id"
        type="text"
        label="TeamsテナントID"
        variant="outlined"
        inputProps={{
          readOnly: props.settingTablekey.length > 0 ? true : false,
        }}
        disabled={props.settingTablekey.length > 0 ? true : false}
        rules={{ required: true }}
        error={errors.teams_tenant_id ? true : false}
      />
      <Controller
        name="enable_jubdge_ui"
        control={control}
        render={({ field }) => (
          <FormControl sx={{ textAlign: "left", mb: 4 }}>
            <FormLabel sx={{ fontSize: "small" }}>
              通知からの承認・却下
            </FormLabel>
            <RadioGroup
              name={field.name}
              onChange={(e) => {
                const value = e.target.value;
                field.onChange(value);
              }}
              row
              value={field.value === undefined ? "" : field.value}
            >
              <FormControlLabel
                value="true"
                control={<Radio />}
                label="有効"
              ></FormControlLabel>
              <FormControlLabel
                value="false"
                control={<Radio />}
                label="無効"
              ></FormControlLabel>
            </RadioGroup>
          </FormControl>
        )}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit(submitForm)}
      >
        保存
      </Button>
      <Loader open={progress} />
    </FormPanel>
  );
};
