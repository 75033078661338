import { Box } from "@mui/material";
import { DataGrid, DataGridProps } from "@mui/x-data-grid";
import React from "react";

interface CrDataGridProps extends DataGridProps {
  height?: number;
}

const CrDataGrid: React.FC<CrDataGridProps> = (props) => {
  const wrapperBoxStyle: any = {
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: "#F5F5F5",
    },
    "& .MuiDataGrid-row": {
      "&:hover": {
        backgroundColor: "#F3FCF8",
      },
    },
  };
  wrapperBoxStyle.height = props.height;

  return (
    <Box sx={wrapperBoxStyle}>
      <DataGrid {...props} />
    </Box>
  );
};
export default CrDataGrid;
