import { FormControl, StandardTextFieldProps, TextField } from "@mui/material";
import React from "react";
import {
  Path,
  RegisterOptions,
  useController,
  useFormContext,
} from "react-hook-form";

interface CustomTextFieldProps extends StandardTextFieldProps {
  name: Path<any>;
  rules?: Omit<RegisterOptions, "setValueAs">;
  variant?: any;
}

export const CnTextField: React.FC<CustomTextFieldProps> = (props) => {
  const { name, rules, ...textFieldProps } = props;
  const { control } = useFormContext();
  const {
    field: { ref, ...rest },
  } = useController({ name, rules, control });

  return (
    <FormControl sx={{ mb: 3 }}>
      <TextField
        id={name}
        inputRef={ref}
        variant={props.variant || "outlined"}
        {...rest}
        {...textFieldProps}
        InputLabelProps={{ shrink: true }}
      />
    </FormControl>
  );
};

export default CnTextField;
