import axios from "axios";

export const fetchPermissions = async (accessToken: string, status: string) => {
  const filterStatus = status || "all";
  const response = await axios({
    method: "get",
    baseURL: process.env.REACT_APP_API_SB_SERVER_URL,
    url: `/admin/permissions?status=${filterStatus}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    validateStatus: (status: any) => {
      return status < 500;
    },
  });
  return response.data;
};
