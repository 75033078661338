import { useAuth0 } from "@auth0/auth0-react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Button, Typography } from "@mui/material";
import {
  GridColDef,
  GridActionsCellItem,
  GridRowParams
} from '@mui/x-data-grid'
import Tooltip from '@mui/material/Tooltip';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import axios from "axios";
import CrDataGrid from "components/molecules/DataGrid";
import { Loader } from "components/molecules/Loader";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const ApiKeyDataGrid: React.FC = () => {
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const [apikeyRows, setApikeyRows] = useState<any>([]);
  const [progress, setProgress] = useState(true);

  const handleCopyClick = React.useCallback(
    (params: GridRowParams) => (event: { stopPropagation: () => void }) => {
      navigator.clipboard.writeText(params.row.apikey);
    },
    []
  )

  const getCopyAction = React.useCallback(
    (params: GridRowParams) => [
      <GridActionsCellItem
        icon={
          <Tooltip title="APIキーをコピーする">
            <ContentCopyIcon />
          </Tooltip>
        }
        label="コピー"
        onClick={handleCopyClick(params)}
        color="inherit"
      />
    ],
    [handleCopyClick]
  )

  const apikeyColumns: GridColDef[] = [
    {
      field: "instance_name",
      headerName: "インスタンス名",
      width: 300,
    },
    {
      field: "apikey",
      headerName: "APIキー",
      width: 300,
    },
    {
      field: 'copyAction',
      headerName: '',
      width: 60,
      type: 'actions',
      getActions: getCopyAction
    } as GridColDef,
  ];

  useEffect(() => {
    (async () => {
      setProgress(true);
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_SB_AUTH0_AUDIENCE,
      });
      const response = await axios({
        method: "get",
        baseURL: process.env.REACT_APP_API_SB_SERVER_URL,
        url: `/apikey`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "content-type": "application/json",
        },
        validateStatus: (status: any) => {
          return status < 500;
        },
      });

      const resData = response.data.apikey_info;
      const rows = resData.map((data: any, index: number) => {
        return {
          id: index,
          instance_name: data.collaboflow_instance_name,
          apikey: data.apikey,
        };
      });
      setApikeyRows(rows);
      setProgress(false);
    })();
  }, []);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          padding: "1rem",
          justifyContent: "space-between",
        }}
      >
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            navigate("/appsettings/collaboflow");
          }}
          startIcon={<ArrowBackIcon />}
        >
          戻る
        </Button>
        <Typography variant="h6" sx={{ ml: 3 }}>
          コラボフローコネクト 連携用APIキー
        </Typography>
        <Box sx={{ width: 110 }}></Box>
      </Box>
      <Box>
        <Box sx={{ textAlign: "left", pl: 4, pb: 1, pt: 1 }}>
          <CrDataGrid
            rows={apikeyRows}
            columns={apikeyColumns}
            pageSize={5}
            autoHeight
            rowsPerPageOptions={[5]}
            sx={{ ml: 4, mr: 4 }}
          ></CrDataGrid>
        </Box>
      </Box>
      <Loader open={progress} />
    </Box>
  );
};
export default ApiKeyDataGrid;
