import { useAuth0 } from "@auth0/auth0-react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Divider, Typography } from "@mui/material";
import axios from "axios";
import FormPanel from "components/molecules/FormPanel";
import { Loader } from "components/molecules/Loader";
import CnTextField from "components/molecules/TextFiled";
import ConfirmDialog from "components/organisms/ConfirmDialog";
import { SnackbarContext } from "context/SnackbarContext";
import { upsertSettings } from "features/collaboflow/apis/Settings";
import React, { useContext, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

interface ConnectionEditParam {
  connectionName: string;
  instanceName: string;
  UserId: string;
  ApiKey: string;
  AppCd: number;
}

const ConnectionEdit: React.FC = () => {
  const navigate = useNavigate();
  const urlParams = useParams();
  const { setSnackbarState } = useContext(SnackbarContext);
  const formMethods = useForm<ConnectionEditParam>({
    defaultValues: {
      connectionName: "",
      instanceName: "",
      UserId: "",
      ApiKey: "",
      AppCd: 1,
    },
  });
  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = formMethods;
  const { getAccessTokenSilently } = useAuth0();
  const [connectionId, setConnectionId] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [queryParams] = useSearchParams();
  const [progress, setProgress] = useState(false);

  /**
   * コラボフロー接続情報の保存
   */
  const submitForm = async (data: any) => {
    setProgress(true);
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_SB_AUTH0_AUDIENCE,
    });
    try {
      const settingId = urlParams.id || "";
      const resPost = await upsertSettings(accessToken, data, settingId);
      const resData = resPost.data;
      setSnackbarState(true, resData.status, resData.messages);
      if (resData.status === "success") {
        navigate("../connection");
      }
    } catch (error) {
      console.log(error, "postAdminSettings");
      setSnackbarState(true, "error", "保存中にエラーが発生しました。");
    } finally {
      setProgress(false);
    }
  };

  /**
   * コラボフロー接続情報の取得
   */
  const fetchConnection = async () => {
    const connectionId = urlParams.id;
    const instanceName = queryParams.get("instance_name");
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_SB_AUTH0_AUDIENCE,
    });
    return await axios({
      method: "get",
      baseURL: process.env.REACT_APP_API_SB_SERVER_URL,
      url: `/admin/setting/${connectionId}?instance_name=${instanceName}`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "content-type": "application/json",
      },
      validateStatus: (status: any) => {
        return status < 500;
      },
    });
  };

  /**
   * コラボフロー接続情報の削除確認
   */
  const handleDelete = async () => {
    setDialogOpen(true);
  };

  /**
   * コラボフロー接続情報の削除実行
   */
  const deleteConnection = async () => {
    const connectionId = urlParams.id;
    const instanceName = queryParams.get("instance_name");
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_SB_AUTH0_AUDIENCE,
    });
    await axios({
      method: "delete",
      baseURL: process.env.REACT_APP_API_SB_SERVER_URL,
      url: "/admin/settings",
      data: {
        pk: connectionId,
        sk: instanceName,
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "content-type": "application/json",
      },
      validateStatus: (status: any) => {
        return status < 500;
      },
    });
    setSnackbarState(true, "success", "設定を削除しました。");
    navigate("../connection");
  };

  /**
   * 既存のコラボフロー接続情報読み込み
   */
  useEffect(() => {
    const connectionId = urlParams.id || "";
    if (connectionId.length > 0) {
      (async () => {
        setProgress(true);
        const response: any = await fetchConnection();
        const status = response.data.status;
        const data = response.data.data;
        if (status === "success") {
          setConnectionId(connectionId);
          setValue("connectionName", data.name);
          setValue("instanceName", data.collaboflow_instance_name);
          setValue("UserId", data.settings.collaboflow.user_id);
          setValue("ApiKey", data.settings.collaboflow.api_key);
          setValue("AppCd", data.settings.collaboflow.app_cd);
        }
        setProgress(false);
      })();
    }
  }, []);

  return (
    <FormProvider {...formMethods}>
      <Box
        sx={{
          display: "flex",
          padding: "1rem",
          justifyContent: "space-between",
        }}
      >
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            navigate("../connection");
          }}
          startIcon={<ArrowBackIcon />}
        >
          戻る
        </Button>
        <Typography variant="h6" sx={{ ml: 3 }}>
          接続情報の追加
        </Typography>
        <Box sx={{ width: 110 }}></Box>
      </Box>
      <Divider variant="middle" />
      <FormPanel sx={{ pt: "2rem", pl: "20rem", pr: "20rem" }}>
        <CnTextField
          name="connectionName"
          label="接続名"
          rules={{ required: true }}
          error={errors.connectionName ? true : false}
        ></CnTextField>
        <CnTextField
          name="instanceName"
          label="インスタンス名"
          rules={{ required: true }}
          error={errors.instanceName ? true : false}
          disabled={connectionId.length > 0 ? true : false}
        ></CnTextField>
        <CnTextField
          name="UserId"
          label="接続ユーザーID"
          rules={{ required: true }}
          error={errors.UserId ? true : false}
        ></CnTextField>
        <CnTextField
          name="ApiKey"
          label="APIキー"
          rules={{ required: true }}
          error={errors.ApiKey ? true : false}
        ></CnTextField>
        <CnTextField
          name="AppCd"
          label="アプリケーションコード"
          rules={{ required: true }}
          error={errors.AppCd ? true : false}
        ></CnTextField>
        <Box sx={{ pt: 0, pb: 7 }}>
          {connectionId.length > 0 && (
            <Box sx={{ display: "inline" }}>
              <Button
                variant="contained"
                color="warning"
                onClick={handleDelete}
                startIcon={<CloseIcon />}
              >
                削除する
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit(submitForm)}
                startIcon={<CheckIcon />}
                sx={{ ml: "3rem" }}
              >
                保存する
              </Button>
            </Box>
          )}
          {!connectionId && (
            <Box sx={{ display: "inline" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit(submitForm)}
                startIcon={<CheckIcon />}
              >
                保存する
              </Button>
            </Box>
          )}
        </Box>
        <ConfirmDialog
          open={dialogOpen}
          setDialogOpen={setDialogOpen}
          dialogMessage={"この接続設定を削除します。本当によろしいですか？"}
          callBack={deleteConnection}
        />
        <Loader open={progress} />
      </FormPanel>
    </FormProvider>
  );
};
export default ConnectionEdit;
