import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Loader } from "./components/molecules/Loader";
import { GlobalNavigation } from "./components/organisms/GlobalNavigation";

export const ProtectedRoute = ({ component }: any) => {
  // ページを認証で保護
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <Loader open={true} />,
  });
  // Eメール確認が行われていない場合は確認を促すページへ
  const { user } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();
  let locationPath = location.pathname;

  useEffect(() => {
    if (user !== undefined && !user.email_verified) {
      navigate("/email-verification");
    }
  }, [user, locationPath, navigate]);
  return (
    <div>
      <GlobalNavigation />
      <Component />
    </div>
  );
};
