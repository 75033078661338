import "./App.css";

function App(props: any) {
  return (
    <div className="App">
      <main className="HomeContainer">{props.children}</main>
    </div>
  );
}

export default App;
