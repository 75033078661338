import { Box } from "@mui/material";
import React, { ReactElement } from "react";

interface TabPanelProps {
  index: number;
  value: number;
  children: ReactElement;
}

/**
 * タブスタイルをセット
 * @param index
 * @returns
 */
export const setTabProps = (index: number) => {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
};

/**
 * タブコンテンツを表示するコンポーネント
 * @param props
 * @returns
 */
export const TabPanel: React.FC<TabPanelProps> = (props: any) => {
  const { children, index, value, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3, pb: 0, pl: 3, pr: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
};
