import { Box, BoxProps } from "@mui/material";
import { styled } from "@mui/material/styles";

const BladeTitleBar = styled(Box)({
  display: "flex",
  width: "100%",
  padding: "1rem",
});

export const BladeTitle: React.FC<BoxProps> = (props) => {
  return <BladeTitleBar>{props.children}</BladeTitleBar>;
};
