import styled from "@mui/styled-engine";
import React from "react";
import { Link, LinkProps } from "react-router-dom";

const LinkComponent = styled(Link)({
  textDecoration: "none",
  color: "inherit",
});

const SimpleLink: React.FC<LinkProps> = (props) => {
  return <LinkComponent to={props.to}>{props.children}</LinkComponent>;
};
export default SimpleLink;
