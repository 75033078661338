import axios from "axios";

/**
 * 接続設定の取得
 */
export const fetchMySettings = async (accessToken: string) => {
  const response = await axios({
    method: "get",
    baseURL: process.env.REACT_APP_API_SB_SERVER_URL,
    url: `/admin/settings`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    validateStatus: (status: any) => {
      return status < 500;
    },
  });
  return response.data;
};

/**
 * 接続設定の登録・更新
 */
export const upsertSettings = async (
  accessToken: string,
  data: any,
  id: string
) => {
  if (id.length === 0) {
    return await postSettings(accessToken, data);
  } else {
    return await putSettings(accessToken, data, id);
  }
};

/**
 * 接続設定の登録
 */
export const postSettings = async (accessToken: string, data: any) => {
  return await axios({
    method: "post",
    baseURL: process.env.REACT_APP_API_SB_SERVER_URL,
    url: "/admin/settings",
    data: {
      name: data.connectionName,
      collaboflow_instance_name: data.instanceName,
      user_id: data.UserId,
      api_key: data.ApiKey,
      app_cd: data.AppCd,
    },
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "content-type": "application/json",
    },
    validateStatus: (status: any) => {
      return status < 500;
    },
  });
};

/**
 * 接続設定の更新
 */
export const putSettings = async (
  accessToken: string,
  data: any,
  id: string
) => {
  return await axios({
    method: "put",
    baseURL: process.env.REACT_APP_API_SB_SERVER_URL,
    url: `/admin/settings/${id}`,
    data: {
      name: data.connectionName,
      collaboflow_instance_name: data.instanceName,
      user_id: data.UserId,
      api_key: data.ApiKey,
      app_cd: data.AppCd,
    },
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "content-type": "application/json",
    },
    validateStatus: (status: any) => {
      return status < 500;
    },
  });
};
