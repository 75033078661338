import { Box, BoxProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";

const FormPanelComponent = styled(Box)({
  display: "flex",
  flexDirection: "column",
  paddingTop: "1rem",
  paddingBottom: "1rem",
  paddingLeft: "3rem",
  paddingRight: "3rem",
  "& .MuiButton-root": {
    maxWidth: 200,
  },
});

interface FormPanelProps extends BoxProps {
  children: React.ReactNode;
}

const FormPanel: React.FC<FormPanelProps> = ({
  children,
  ...formPanelProps
}) => {
  return (
    <FormPanelComponent {...formPanelProps}>{children}</FormPanelComponent>
  );
};

export default FormPanel;
