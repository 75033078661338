import { useAuth0 } from "@auth0/auth0-react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Button,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import axios from "axios";
import { Loader } from "components/molecules/Loader";
import ConfirmDialog from "components/organisms/ConfirmDialog";
import { SnackbarContext } from "context/SnackbarContext";
import React, { ReactNode, useContext, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { formatLocalZonedDate } from "utils/Formatter";
import { JudgeLabel } from "../../../../const/labels";

interface FieldListItemProps {
  title: string;
  value: ReactNode;
}

/**
 * フィールド形式表示用リストアイテム
 * @param props
 * @returns
 */
const FieldListItem: React.FC<FieldListItemProps> = (props) => {
  return (
    <ListItemText
      primary={
        <React.Fragment>
          <Typography
            sx={{ display: "inline" }}
            component="span"
            color="text.secondary"
            variant="body2"
          >
            {props.title}
          </Typography>
        </React.Fragment>
      }
      secondary={
        <React.Fragment>
          <Typography
            sx={{ display: "inline" }}
            component="span"
            color="text.primary"
          >
            {props.value}
          </Typography>
        </React.Fragment>
      }
    ></ListItemText>
  );
};

/**
 * 外部接続情報詳細表示
 * @returns
 */
const ExternalConnectionEdit: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [queryParams] = useSearchParams();
  const requestType = queryParams.get("type") ?? "receive";
  const { getAccessTokenSilently } = useAuth0();
  const [permissionInfo, setPermissionInfo] = useState({
    origin_instance: "",
    permission_request_mail_address: "",
    permission_request_message: "",
    permission_request_datetime: "",
    permission_request_status: "",
    permission_instance: "",
    permission_route: [],
    title: "",
  });
  const [progress, setProgress] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const { setSnackbarState } = useContext(SnackbarContext);

  /**
   * 指定された外部接続情報の取得
   */
  const fetchExternalConnection = async () => {
    setProgress(true);
    const connectionId = params.id;
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_SB_AUTH0_AUDIENCE,
    });
    const response = await axios({
      method: "get",
      baseURL: process.env.REACT_APP_API_SB_SERVER_URL,
      url: `/admin/permission/${connectionId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "content-type": "application/json",
      },
      validateStatus: (status: any) => {
        return status < 500;
      },
    });

    const resData = response.data.data;
    const resDataPermissionRequest = resData.permission.permission_request[0];
    setProgress(false);
    setPermissionInfo({
      origin_instance: resData.origin_instance,
      permission_request_mail_address: resDataPermissionRequest.mail_address,
      permission_request_message: resDataPermissionRequest.message,
      permission_request_datetime: resDataPermissionRequest.request_datetime,
      permission_request_status: resDataPermissionRequest.status,
      permission_instance: resData.permission_instance,
      permission_route: resData.permission.permission_route,
      title: resData.title,
    });
  };
  /**
   * 外部接続情報の削除確認
   */
  const handleDelete = async () => {
    setDialogOpen(true);
  };

  /**
   * 外部接続情報の削除実行
   */
  const deleteConnection = async () => {
    const connectionId = params.id;
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_SB_AUTH0_AUDIENCE,
    });
    await axios({
      method: "delete",
      baseURL: process.env.REACT_APP_API_SB_SERVER_URL,
      url: `/admin/permission/${connectionId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "content-type": "application/json",
      },
      validateStatus: (status: any) => {
        return status < 500;
      },
    });
    setSnackbarState(true, "success", "外部接続情報を削除しました。");

    // 遷移先を切り替える
    const toUrl = requestType === "send" ? "../connection" : "../connection/external";
    navigate(toUrl);
  };

  /**
   * 初回読み込み時の処理
   */
  useEffect(() => {
    fetchExternalConnection();
  }, []);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          padding: "1rem",
          justifyContent: "space-between",
        }}
      >
        <Button
          variant="outlined"
          color="primary"
          onClick={() => navigate(-1)}
          startIcon={<ArrowBackIcon />}
        >
          戻る
        </Button>
        <Typography sx={{ ml: 3 }} variant={"h6"}>
          外部のコラボフロー接続情報
        </Typography>
        <Box sx={{ width: 110 }}></Box>
      </Box>
      <Divider variant="middle" />
      <Box
        sx={{
          display: "flex",
          padding: "1rem",
          justifyContent: "center",
          flexDirection: "row",
          alignItems: "flex-start",
        }}
      >
        <Box sx={{ pt: "2rem", pl: "2rem", pr: "2rem" }}>
          <Typography>リクエストした内容</Typography>
          <List>
            <ListItem>
              <FieldListItem title="接続名" value={permissionInfo.title} />
            </ListItem>
            <ListItem>
              <FieldListItem
                title="依頼元インスタンス名"
                value={permissionInfo.origin_instance}
              />
            </ListItem>
            <ListItem>
              <FieldListItem
                title="依頼日"
                value={formatLocalZonedDate(
                  permissionInfo.permission_request_datetime
                )}
              />
            </ListItem>
            <ListItem>
              <FieldListItem
                title="依頼時のメッセージ"
                value={permissionInfo.permission_request_message}
              />
            </ListItem>
            <ListItem>
              <FieldListItem
                title="状態"
                value={
                  <Chip
                    label={JudgeLabel[permissionInfo.permission_request_status]}
                    variant={"outlined"}
                  />
                }
              />
            </ListItem>
          </List>
        </Box>
        {permissionInfo.permission_instance !== undefined && (
          <Box sx={{ pt: "2rem", pl: "2rem", pr: "2rem" }}>
            <Typography>許可された内容</Typography>
            <List>
              <ListItem>
                <FieldListItem
                  title="許可されたインスタンス名"
                  value={permissionInfo.permission_instance}
                />
              </ListItem>
              <ListItem>
                <FieldListItem
                  title="許可された経路"
                  value={permissionInfo.permission_route.map((item: any) => (
                    <div>{item.route_name}</div>
                  ))}
                />
              </ListItem>
            </List>
          </Box>
        )}
      </Box>
      <Box sx={{ pt: 0, pb: 7 }}>
        <Button
          variant="contained"
          color="warning"
          onClick={handleDelete}
          startIcon={<CloseIcon />}
          sx={{ ml: "3rem" }}
        >
          削除する
        </Button>
        {requestType === "receive" && (
          // 受け取った接続依頼のみ経路の編集ボタンを表示する
          <Button
            variant="contained"
            startIcon={<EditIcon />}
            sx={{ ml: 2 }}
            onClick={() => navigate(`/appsettings/collaboflow/connection/external/${params.id}?back=true`)}
          >
            編集する
          </Button>
        )}
      </Box>
      <ConfirmDialog
        open={dialogOpen}
        setDialogOpen={setDialogOpen}
        dialogMessage={"この接続依頼を削除します。本当によろしいですか？"}
        callBack={deleteConnection}
      />
      <Loader open={progress} />
    </Box>
  );
};

export default ExternalConnectionEdit;
