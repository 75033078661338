import { createContext, useState } from "react";
export const SnackbarContext = createContext();

export const SnackbarContextProvider = (props) => {
  const [snackbarState, setState] = useState({
    isOpen: false,
    type: "info",
    message: "",
  });

  const setSnackbarState = (isOpen, type, message) => {
    setState({
      isOpen: isOpen,
      type: type,
      message: message,
    });
  };

  return (
    <SnackbarContext.Provider value={{ snackbarState, setSnackbarState }}>
      {props.children}
    </SnackbarContext.Provider>
  );
};
