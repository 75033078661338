import { useAuth0 } from "@auth0/auth0-react";
import LogoutIcon from "@mui/icons-material/Logout";
import { AppBar, Box, Button, Toolbar } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { Link } from "react-router-dom";
import CollaboflowLogo from "../../assets/images/collaboflow_logo.png";

const CCAppBar = styled(AppBar)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

export const GlobalNavigation: React.FC = () => {
  const { user, logout, isAuthenticated } = useAuth0();
  const userName = user !== undefined ? user.name : "";
  return (
    <CCAppBar>
      <Toolbar>
        <Link to="/">
          <img
            alt="CollaboConnect"
            src={CollaboflowLogo}
            width="200"
            height="30"
          />
        </Link>
        <div className="CCAppBar__ServiceName">連携サービスベース</div>
      </Toolbar>
      <div className="CCAppBar__Right">
        <Box sx={{ display: "inline-block" }}>
          {isAuthenticated ? userName : ""}
        </Box>
        <Button
          color="inherit"
          onClick={() => logout({ returnTo: window.location.origin })}
          startIcon={<LogoutIcon />}
          sx={{ pr: 3, pl: 3 }}
        >
          ログオフ
        </Button>
      </div>
    </CCAppBar>
  );
};
