import { format as formatTz, utcToZonedTime } from "date-fns-tz";

/**
 * リクエスト日付時刻のフォーマッタ
 */
export const formatLocalZonedDate = (DateString: string) => {
  const dataString = DateString || "";
  if (dataString.length === 0) {
    return "";
  }
  let zonedDate = utcToZonedTime(dataString, "Asia/Tokyo");
  return formatTz(zonedDate, "yyyy/MM/dd HH:mm");
};
