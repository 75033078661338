export const fetchAuth0UserInfo = (user: any) => {
  if (user !== undefined) {
    return {
      sub: user.sub !== undefined ? user.sub : "",
      email: user.email !== undefined ? user.email : "",
      name: user.name !== undefined ? user.name : "",
      nickname: user.nickname !== undefined ? user.nickname : "",
      picture: user.picture !== undefined ? user.picture : "",
      valid: true,
    };
  }
  return {
    sub: "",
    email: "",
    name: "",
    nickname: "",
    picture: "",
    valid: false,
  };
};
