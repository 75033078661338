import { useAuth0 } from "@auth0/auth0-react";
import { Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import axios from "axios";
import React, { useContext, useState } from "react";
import { Loader } from "../components/molecules/Loader";
import { SnackbarContext } from "../context/SnackbarContext";

const EmailVarificationComponent = styled("div")({
  padding: 50,
});

const Home: React.FC = () => {
  const { setSnackbarState } = useContext(SnackbarContext);
  const [progress, setProgress] = useState(false);
  const { user, getAccessTokenSilently } = useAuth0();

  const handleVerificationEmail = async () => {
    setProgress(true);
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_SB_AUTH0_AUDIENCE,
    });
    const userId = user?.sub;
    try {
      const apiRes = await axios({
        method: "post",
        baseURL: process.env.REACT_APP_API_SERVER_URL,
        url: "/user/verification",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: {
          userId: userId,
        },
        validateStatus: (status: any) => {
          return status < 500;
        },
      });
      setSnackbarState(true, apiRes.data.status, apiRes.data.messages);
    } catch (error) {}
    setProgress(false);
  };

  return (
    <EmailVarificationComponent>
      <Typography variant="h5">メール認証を行ってください</Typography>
      <Box sx={{ p: 4 }}>
        <p>
          アカウントの認証を行うため、登録いただいた下記アドレスに認証メールを送信しました。
        </p>
        {user && <p style={{ fontWeight: "bold" }}>{user.email}</p>}
        <p>
          メール本文の「認証する」をクリックして、認証を完了してください。
          <br />
          認証リンクはメール送信から24時間有効です。
        </p>
        <p>
          ※お使いのメールサービスによってはメールが迷惑メールなどに到着している場合がございます。
          <br />
          メールが届かない場合はご確認をお願いします。
        </p>
        <Box sx={{ p: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleVerificationEmail}
          >
            認証メールを再送する
          </Button>
        </Box>
      </Box>
      <Loader open={progress} />
    </EmailVarificationComponent>
  );
};
export default Home;
