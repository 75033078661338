import CollaboflowConnectionEdit from "features/collaboflow/components/ConnectionSettings/ConnectionEdit";
import CollaboflowExternalConnectionRequest from "features/collaboflow/components/ConnectionSettings/ConnectionRequest";
import CollaboflowExternalConnection from "features/collaboflow/components/ConnectionSettings/ExternalConnection";
import CollaboflowExternalConnectionEdit from "features/collaboflow/components/ConnectionSettings/ExternalConnectionEdit";
import CollaboflowConnectionSettings from "features/collaboflow/components/ConnectionSettings/Main";
import PermissionRequest from "features/collaboflow/components/ConnectionSettings/PermissionRequest";
import LicenseRegistrationPanel from "features/collaboflow/components/LicenseRegistrationPanel";
import LogDataGird from "features/collaboflow/components/LogDataGird";
import CollaboflowMenu from "features/collaboflow/components/Menu";
import Collaboflow from "features/collaboflow/pages/Main";
import MicrosoftTeams from "features/microsoft-teams/pages/Main";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App";
import { Auth0ProviderWithConfig } from "./Auth0ProviderWithConfig";
import { Notification } from "./components/organisms/Notification";
import { SnackbarContextProvider } from "./context/SnackbarContext";
import EmailVerification from "./pages/EmailVerification";
import Home from "./pages/Home";
import { ProtectedRoute } from "./ProtectedRoute ";
import ApiKeyDataGrid from "features/collaboflow/components/ApiKeyDataGrid";

const RouterConfig: React.FC = () => (
  <BrowserRouter>
    <Auth0ProviderWithConfig>
      <App>
        <SnackbarContextProvider>
          <Notification></Notification>
          <Routes>
            <Route index element={<ProtectedRoute component={Home} />} />
            <Route
              path="/appsettings/microsoft-teams"
              element={<ProtectedRoute component={MicrosoftTeams} />}
            />
            <Route
              path="/appsettings/collaboflow"
              element={<ProtectedRoute component={Collaboflow} />}
            >
              <Route index element={<CollaboflowMenu />} />
              <Route
                path="connection"
                element={<CollaboflowConnectionSettings />}
              />
              <Route
                path="connection/edit"
                element={<CollaboflowConnectionEdit />}
              />
              <Route
                path="connection/edit/:id"
                element={<CollaboflowConnectionEdit />}
              />
              <Route
                path="connection/external"
                element={<CollaboflowExternalConnection />}
              />
              <Route
                path="connection/external/:id"
                element={<ProtectedRoute component={PermissionRequest} />}
              />
              <Route
                path="connection/external/request"
                element={<CollaboflowExternalConnectionRequest />}
              />
              <Route
                path="connection/external/edit/:id"
                element={<CollaboflowExternalConnectionEdit />}
              />
              <Route path="license" element={<LicenseRegistrationPanel />} />
              <Route path="log" element={<LogDataGird />} />
              <Route
                path="apikey"
                element={<ApiKeyDataGrid />}
              />
            </Route>
            <Route
              path="/email-verification"
              element={<ProtectedRoute component={EmailVerification} />}
            />
          </Routes>
        </SnackbarContextProvider>
      </App>
    </Auth0ProviderWithConfig>
  </BrowserRouter>
);
export default RouterConfig;
